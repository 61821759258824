import { useMemo } from 'react'
import { FEATURE_FLAG } from '../constants'
import { useFeatureFlag } from '../redux/slices/appConfig'
import { useAppContext } from '../redux/slices/appContext'
import { useCheckPolicy } from './index'

/**
 * Determines if conversations
 * @return {boolean}
 */
export function useIsConversationsActive (otherwise = true) {
  const { active } = useFeatureFlag(FEATURE_FLAG.CONVERSATIONS)
  const { isSummitUser } = useAppContext()

  return useMemo(() => (active || isSummitUser) && otherwise, [active, isSummitUser, otherwise])
}

/**
 * Determines if data home is active
 * @return {boolean}
 */
export function useIsDataHomeActive () {
  const { active } = useFeatureFlag(FEATURE_FLAG.DATA_HOME)
  const { isSummitUser } = useAppContext()

  return useMemo(() => active || isSummitUser, [active, isSummitUser])
}

/**
 * Determines if data home is active
 * @return {boolean}
 */
export function useIsMediaManagementActive () {
  const { active } = useFeatureFlag(FEATURE_FLAG.MEDIA_MANAGEMENT)
  const { isSummitUser } = useAppContext()

  return useMemo(() => active || isSummitUser, [active, isSummitUser])
}

export function useIsDenaliLabsActive () {
  return useCheckPolicy('denali_labs')
}

/**
 * Determines if rebalancer is active
 * @return {boolean}
 */
export function useIsRebalancerActive () {
  const { active } = useFeatureFlag(FEATURE_FLAG.REBALANCER)
  const { isSummitUser } = useAppContext()

  return useMemo(() => active || isSummitUser, [active, isSummitUser])
}

export function useIsSchwabApiActive () {
  const { active } = useFeatureFlag(FEATURE_FLAG.SCHWAB_API)
  const { isSummitUser } = useAppContext()

  return useMemo(() => active || isSummitUser, [active, isSummitUser])
}
