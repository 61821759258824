import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormLabel, Grid, TextField, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import Text from '../atoms/Text'
import { messageToUse, useStyles } from '../pages/admin/common'
import CustomSelect from '../molecules/CustomSelect'
import AceCodeEditor from '../molecules/AceCodeEditor'

const boolOptions = [
  {
    key: 0,
    value: '0',
    label: 'No'
  },
  {
    key: 1,
    value: '1',
    label: 'Yes'
  }
]

const AbundanceEngineViewForm = ({ header }) => {
  const theme = useTheme()
  const classes = useStyles()

  const { register, formState, setValue, clearErrors, watch } =
    useFormContext()

  const { errors, defaultValues } = formState

  const handleFieldChange = (viewFieldName) => (value) => {
    clearErrors(viewFieldName)
    setValue(viewFieldName, value)
  }

  const viewContent = watch('viewContent')

  return (
    <form className={classes.form}>
      <input type='hidden' {...register('viewId', { required: false })} />
      <input type='hidden' {...register('viewVersion')} />
      <Grid container spacing={3}>
        {header && (
          <Grid container item xs={12}>
            {header}
          </Grid>
        )}
        <Grid container item xs={12} md={3}>
          <Grid item xs={12}>
            <div className={classes.field}>
              <FormLabel htmlFor='viewName' className={classes.label}>
                View Name
              </FormLabel>
              <TextField
                error={Boolean(errors.viewName)}
                id='viewName'
                placeholder='Add View Name'
                inputProps={register('viewName', {
                  minLength: 2,
                  maxLength: 50,
                  required: true
                })}
                fullWidth
              />
              {errors.viewName && (
                <Text
                  text={messageToUse(errors.viewName)}
                  color={theme.palette.error.main}
                />
              )}
            </div>
            <div className={classes.field}>
              <FormLabel htmlFor='viewPath' className={classes.label}>
                Path
              </FormLabel>
              <TextField
                error={Boolean(errors.viewPath)}
                id='viewPath'
                placeholder='Add Path'
                inputProps={register('viewPath', {
                  minLength: 2,
                  maxLength: 50,
                  required: true
                })}
                fullWidth
              />
              {errors.viewPath && (
                <Text
                  text={messageToUse(errors.viewPath)}
                  color={theme.palette.error.main}
                />
              )}
            </div>
            <div className={classes.field}>
              <FormLabel htmlFor='viewDescription' className={classes.label}>
                Description
              </FormLabel>
              <TextField
                error={Boolean(errors.viewDescription)}
                id='viewDescription'
                placeholder='Add Description'
                inputProps={register('viewDescription', {
                  minLength: 2,
                  maxLength: 50,
                  required: true
                })}
                fullWidth
              />
              {errors.viewDescription && (
                <Text
                  text={messageToUse(errors.viewDescription)}
                  color={theme.palette.error.main}
                />
              )}
            </div>
            <div className={classes.field}>
              <FormLabel htmlFor='viewIsLive' className={classes.label}>
                Is Live
              </FormLabel>
              <input type='hidden' {...register('viewIsLive')} />
              <CustomSelect
                defaultValue={defaultValues.viewIsLive}
                onChange={handleFieldChange('viewIsLive')}
                className={classes.fullWidth}
                options={boolOptions}
              />
              {errors.viewIsLive && (
                <Text
                  text={messageToUse(errors.viewIsLive)}
                  color={theme.palette.error.main}
                />
              )}
            </div>
            <div className={classes.field}>
              <FormLabel htmlFor='showInNavigation' className={classes.label}>
                Display in side navigation?
              </FormLabel>
              <input type='hidden' {...register('showInNavigation')} />
              <CustomSelect
                defaultValue={defaultValues.showInNavigation}
                onChange={handleFieldChange('showInNavigation')}
                className={classes.fullWidth}
                options={boolOptions}
              />
              {errors.showInNavigation && (
                <Text
                  text={messageToUse(errors.showInNavigation)}
                  color={theme.palette.error.main}
                />
              )}
            </div>
            <div className={classes.field}>
              <FormLabel htmlFor='hideTitle' className={classes.label}>
                Hide main title in view?
              </FormLabel>
              <input type='hidden' {...register('hideTitle')} />
              <CustomSelect
                defaultValue={defaultValues.hideTitle}
                onChange={handleFieldChange('hideTitle')}
                className={classes.fullWidth}
                options={boolOptions}
              />
              {errors.hideTitle && (
                <Text
                  text={messageToUse(errors.hideTitle)}
                  color={theme.palette.error.main}
                />
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={9}>
          <Grid item xs={12}>
            <div className={clsx(classes.field, classes.editor)}>
              <FormLabel htmlFor='viewContent' className={classes.label}>
                Content
              </FormLabel>
              <input
                type='hidden'
                {...register('viewContent', { required: true })}
              />
              <AceCodeEditor
                tabSize={2}
                id='view-editor'
                name='viewContent'
                value={viewContent}
                defaultValue={defaultValues.viewContent}
                onChange={handleFieldChange('viewContent')}
              />
              {errors.viewContent && (
                <Text
                  text={messageToUse(errors.viewContent)}
                  color={theme.palette.error.main}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

AbundanceEngineViewForm.propTypes = {
  header: PropTypes.node,
  onChange: PropTypes.func
}

export default memo(AbundanceEngineViewForm)
